exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accessories-js": () => import("./../../../src/pages/accessories.js" /* webpackChunkName: "component---src-pages-accessories-js" */),
  "component---src-pages-belt-manufacture-js": () => import("./../../../src/pages/belt-manufacture.js" /* webpackChunkName: "component---src-pages-belt-manufacture-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-materials-used-js": () => import("./../../../src/pages/materials-used.js" /* webpackChunkName: "component---src-pages-materials-used-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-production-js": () => import("./../../../src/pages/production.js" /* webpackChunkName: "component---src-pages-production-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-what-is-wire-mesh-belt-js": () => import("./../../../src/pages/what-is-wire-mesh-belt.js" /* webpackChunkName: "component---src-pages-what-is-wire-mesh-belt-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

